import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import {
  Heading,
  Field,
  InputGroup,
  Spacer,
  Text,
  FormRow,
  Input as GuiInput,
  Message,
  Grid,
  Button,
  Flex,
} from '@oliasoft-open-source/react-ui-library';
import { round, getUnit, getValue } from '@oliasoft-open-source/units';
import {
  addCostItem,
  initialCostItem,
} from '~store/entities/cost-setup/cost-setup';
import { getResolver } from '~src/validation/resolver';
import translations from '~src/internationalisation/translation-map.json';
import { costItemsSchemaValidator } from '~schemas/ajv-validators';
import {
  Input,
  CheckBoxList,
  Select,
  NumberInput,
  UnitInput,
  UnitInputConvertable,
} from '~common/form-inputs';
import { convertToStorageUnits } from '~common/units/units';
import { CostType, QuantityType } from '~src/enums/cost-setup';
import { useAutoSave } from '~common/auto-save/use-auto-save';
import { getSelectOptions } from '~common/lists/lists';
import { selectCurrencies } from '~store/entities/company-settings/selectors';
import { autoSaveWaitShort } from '~src/config/config';
import { selectSectionSuggestedValues } from '~store/entities/well-details/selectors';
import { linkFields } from '~src/enums/settings';
import { selectSectionOperations } from '~store/entities/activity-model/selectors';
import { routes } from '~routes/routes';
import { navigateToRoute } from '~store/navigation/navigation';
import { useParams } from 'react-router';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';

const linkFieldMap = {
  length: 'linkedValue',
  volume: 'linkedValue',
};

const CostItemForm = ({
  costItem,
  addCostItem,
  operations,
  projectId,
  currenciesOptions,
  isPageDisabled,
  unitSettings,
  suggestedOptions,
  sectionOperations,
  navigateToRoute,
}) => {
  const { t } = useTranslation();

  const { company } = useParams();

  const isVendor = !!costItem?.vendor;

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: initialCostItem,
    resolver: getResolver(costItemsSchemaValidator),
  });

  useEffect(() => {
    const costData = costItem || initialCostItem;
    if (costData) {
      reset({
        ...costData,
        length: `${costData.length}|m`,
        volume: `${costData.volume}|m3`,
      });
    }
  }, [costItem?.costItemId, suggestedOptions.length, reset]);
  const debounceAddCostItem = useRef(debounce(addCostItem, autoSaveWaitShort));
  const onSubmit = handleSubmit((data) => {
    data.projectId = projectId;
    const convertedCost = convertToStorageUnits(data, ['length', 'volume'], {
      length: 'm',
      volume: 'm3',
    });
    debounceAddCostItem.current(convertedCost);
  });
  useAutoSave(onSubmit, watch);

  const typeList = [
    { label: t(translations.costSetup_dayRate), value: CostType.DayRate },
    { label: t(translations.costSetup_lumpSum), value: CostType.LumpSum },
    { label: t(translations.costSetup_quantity), value: CostType.Quantity },
  ];

  const quantityList = [
    { label: t(translations.costSetup_length), value: QuantityType.Length },
    { label: t(translations.costSetup_volume), value: QuantityType.Volume },
  ];

  const { type, currency, quantity, length, volume, unitCost, linkedValue } =
    watch();

  const selectedUnit = () => {
    return quantity === QuantityType.Length
      ? getUnit(length) || unitSettings?.length
      : getUnit(volume) || 'm3';
  };

  const onQuantityChange = () => {
    setValue('linkedValue', linkFields.CUSTOM);
    setValue('length', '0|m');
    setValue('volume', '0|m3');
  };
  return (
    <>
      <Grid gap>
        <Heading top>{watch('name')}</Heading>
        {isVendor && (
          <Message
            message={{
              content: (
                <Flex justifyContent={'space-between'}>
                  <Text>
                    {t(
                      translations.priceBook_thisCostItemIsConnectedToThePriceBook,
                    )}
                  </Text>
                  <Button
                    small
                    label={'View price book'}
                    onClick={() =>
                      navigateToRoute(routes.companySettings.nested.priceBook, {
                        company,
                      })
                    }
                  />
                </Flex>
              ),
              onClose: function Xs() {},
              type: 'Info',
              visible: true,
            }}
          />
        )}
      </Grid>
      <Spacer />
      <form>
        <FormRow>
          <Field label={t(translations.name)}>
            <Input
              name="name"
              control={control}
              errors={errors}
              width="200px"
              disabled={isPageDisabled || isVendor}
            />
          </Field>
          <Field label={t(translations.type)}>
            <Select
              name="type"
              control={control}
              errors={errors}
              options={typeList}
              width="125px"
              disabled={isPageDisabled || isVendor}
            />
          </Field>
          {type !== CostType.Quantity && (
            <Field label={t(translations.cost)}>
              <InputGroup width="200px">
                <NumberInput
                  name="cost"
                  control={control}
                  errors={errors}
                  disabled={isPageDisabled || isVendor}
                />

                <Select
                  name="currency"
                  control={control}
                  errors={errors}
                  options={currenciesOptions}
                  width="80px"
                  disabled={isPageDisabled || isVendor}
                />
              </InputGroup>
            </Field>
          )}
        </FormRow>
        {type === CostType.Quantity && (
          <FormRow>
            <Field label={t(translations.costSetup_quantity)}>
              <Select
                name="quantity"
                control={control}
                errors={errors}
                onChange={onQuantityChange}
                options={quantityList}
                width="125px"
                disabled={isPageDisabled || isVendor}
              />
            </Field>
            {quantity === QuantityType.Length && (
              <Field label={t(translations.length)}>
                <UnitInputConvertable
                  name="length"
                  unitkey={'length'}
                  control={control}
                  errors={errors}
                  predefinedOptions={suggestedOptions}
                  selectedPredefinedOptionKey={linkedValue}
                  onPredefinedChange={setValue}
                  linkFieldMap={linkFieldMap}
                  disabled={isPageDisabled}
                  disabledUnit
                />
              </Field>
            )}
            {quantity === QuantityType.Volume && (
              <Field label={t(translations.costSetup_volume)}>
                <UnitInputConvertable
                  name="volume"
                  unitkey={'volume'}
                  control={control}
                  errors={errors}
                  predefinedOptions={suggestedOptions}
                  selectedPredefinedOptionKey={linkedValue}
                  linkFieldMap={linkFieldMap}
                  onPredefinedChange={setValue}
                  disabled={isPageDisabled}
                  disabledUnit
                />
              </Field>
            )}
            <Field label={t(translations.costSetup_unitCost)}>
              <UnitInput
                name="unitCost"
                unit={`${currency}/${selectedUnit()}`}
                control={control}
                errors={errors}
                disabled={isPageDisabled || isVendor}
              />
            </Field>
            <Field label={t(translations.costSetup_totalCost)}>
              <InputGroup>
                <GuiInput
                  name="totalCost"
                  disabled={true}
                  value={round(
                    quantity === QuantityType.Length
                      ? getValue(length) * unitCost
                      : getValue(volume) * unitCost,
                    2,
                  )}
                />
                <Select
                  name="currency"
                  control={control}
                  errors={errors}
                  options={currenciesOptions}
                  width="80px"
                  disabled={isPageDisabled}
                />
              </InputGroup>
            </Field>
          </FormRow>
        )}
        {isVendor && (
          <FormRow>
            <Field label={t(translations.priceBook_vendor)}>
              <Input
                name="vendor"
                control={control}
                errors={errors}
                width="200px"
                disabled={isPageDisabled || isVendor}
              />
            </Field>
          </FormRow>
        )}
        <FormRow>
          <Heading>{t(translations.costSetup_operations)}</Heading>
          <Spacer height="var(--padding-xs)" />
          <Text
            link
            onClick={() =>
              isPageDisabled
                ? false
                : setValue(
                    'operations',
                    operations.map((operation) => operation.id),
                  )
            }
          >
            {t(translations.checkAll)}
          </Text>
          <Spacer width="var(--padding-sm)" />
          <Text
            link
            onClick={() =>
              isPageDisabled ? false : setValue('operations', [])
            }
          >
            {t(translations.uncheckAll)}
          </Text>
        </FormRow>
        <Spacer height="var(--padding-xs)" />
        {sectionOperations?.map((section) => {
          return (
            <div key={section.sectionId}>
              <Spacer height="var(--padding-xs)" />
              <Text faint>{section.name}</Text>
              <CheckBoxList
                items={section.operations}
                control={control}
                errors={errors}
                name="operations"
              />
            </div>
          );
        })}
      </form>
    </>
  );
};

const mapStateToProps = ({ entities }) => {
  const { activityModel, userSettings } = entities;
  const operations = activityModel.operations.map((operation) => ({
    id: operation.operationId.toString(),
    name: operation.name,
  }));

  const currencies = selectCurrencies(entities);
  const currenciesOptions = getSelectOptions(currencies);
  const suggestedOptions = selectSectionSuggestedValues({ entities });
  const sectionOperations = selectSectionOperations({ entities });
  return {
    operations,
    currenciesOptions,
    unitSettings: userSettings.settings.units,
    suggestedOptions,
    sectionOperations,
  };
};

const mapDispatchToProps = { addCostItem, navigateToRoute };

const Container = withErrorBoundary(
  connect(mapStateToProps, mapDispatchToProps)(CostItemForm),
);

export { Container as CostItemForm };
